import { createRouter, createWebHistory } from "vue-router";

import Home from "@/views/Home.vue";
import Dashboard from "@/views/Dashboard.vue";
import Layout from "@/views/Layout.vue";
import Booking from "@/views/Booking.vue"
import Book from '@/views/Show.vue'
import Order from '@/views/Order.vue'
import CheckIn from '@/views/Payment.vue'

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/checkin",
    name: "CheckIn",
    component: CheckIn,
  },

  {
    path: "/dashboard"
    ,
    name: "Dashboard",
    component: Dashboard,
  },

  {
    path: "/layout",
    name: "Layout",
    component: Layout,
  },

  {
    path: "/booking",
    name: "Booking",
    component: Booking,
  },


  {
    path: "/book",
    name: "Book",
    component: Book,
  },
  {
    path: "/book/:id",
    name: "Order",
    component: Order,
  },

];

const router = createRouter({
  history: createWebHistory(), //process.env.BASE_URL
  routes,
});

export default router;
