<template>
  <div class="bg-home vh100">
    <header class="container">
      <div class="row mx-auto">
        <img src="../assets/logo.png" class="img-fluid mx-auto" style="width: 65%;">
        <h1 class="text-center text-white">ผังงาน</h1>
      </div>
    </header>

    <Layout_Grid />

    <div class="d-grid gap-2 mt-3 mx-5">
      <button type="button" class="btn btn-light rounded-pill" @click="go_layout">จองโต๊ะที่นั่ง</button>
    </div>


  </div>
</template>

<script setup>
import { onMounted, inject } from "vue";
import Layout_Grid from "../components/Layout_Grid.vue";

const store = inject("store");
const router = inject("router");
onMounted(() => {
  if (!store.getters.getAccessToken.accesstoken) {
    router.push({ name: "Home" });
  }
});

const go_layout = () => {
  router.push({ name: "Booking" });
};


</script>

<!-- <script>
import { defineComponent, onMounted, inject } from "vue";
// import { ErrorMessage, Field, Form } from "vee-validate";
export default defineComponent({
  name: "Dashboard",
  // components: {
  //   ErrorMessage,
  //   Field,
  //   Form,
  // },
  setup() {
    const store = inject("store");
    const router = inject("router");
    onMounted(() => {
      if (!store.getters.getAccessToken.accesstoken) {
        router.push({ name: "Home" });
      }
    });

    const go_layout = () => {
      router.push({ name: "Layout" });
    };

    return {
      go_layout
    };
  },
}); 
</script>
-->





<style scoped>
.row {
  justify-content: center;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
}

label,
.form-control {
  display: inline-block;

}

label {
  float: left;
  width: auto;
  text-align: right;
  padding-right: 10px;
}

.form-control {
  float: left;
  width: 250px;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: none !important;
  border-bottom: 1rem solid #FFFA8A;
}



.title {
  font-size: 5rem;
}


.back-button {
  color: white !important;
  position: relative;
  box-sizing: border-box;
  text-align: center;
  width: 150px;
  border-radius: 25px;
  border: 2px solid #ffffff !important;
  background-color: var(--color);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  padding: 8px 10px 8px 10px;
}

.back-button:hover,
.submit-button:focus {
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
  color: rgba(0, 0, 0, 0.65);
}

.back-button:hover {
  transform: translateY(-1px);
}

.back-button:active {
  background-color: var(--color);
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
  color: rgba(0, 0, 0, 0.65);
  transform: translateY(0);
}


.submit-button {
  color: #4E6629 !important;
  position: relative;
  box-sizing: border-box;
  text-align: center;
  width: 150px;
  border-radius: 25px;
  border: none;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  padding: 8px 10px 8px 10px;
}

.submit-button:hover,
.submit-button:focus {
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
  color: rgba(0, 0, 0, 0.65);
}

.submit-button:hover {
  transform: translateY(-1px);
}

.submit-button:active {
  background-color: #f0f0f1;
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
  color: rgba(0, 0, 0, 0.65);
  transform: translateY(0);
}


</style>
