<template>
  <div class="p-4 bg-body min-vh-100">
    <header class="container">
      <div class="row mx-auto">
        <img src="../assets/logo.png" class="img-fluid mx-auto" style="width: 40%;">
        <h1 class="text-center text-white">Check-in</h1>
      </div>
    </header>

    <div class="card mt-3">
      <div class="card-body">
        <!-- {{ userData }} -->
        <span class="d-block fs-4 fw-bold">หมายเลขโต๊ะ</span>
        <span class="d-block mx-3 my-3 fs-1">{{ table?.table_code }}</span>
        <hr>

        <span class="d-block fs-4 fw-bold">ชื่อผู้จอง</span>
        <span class="d-block mx-3 my-3 fs-1">{{ (userData?.booking_name ? userData?.booking_name : `${userData?.firstname}
                  ${userData?.lastname}`) }}</span>
        <hr>

        <span v-if="userData?.booking_name">
          <span class="d-block fs-4 fw-bold">จองโดย</span>
          <span class="d-block mx-3 my-3 fs-1">{{ `${userData?.firstname} ${userData?.lastname}` }}</span>
          <hr>
        </span>

        <span class="d-block fs-4 fw-bold">จองวันที่</span>
        <span class="d-block mx-3 my-3 fs-1">{{ dateThai(userData?.created_at) }}</span>
        <hr>

      </div>
    </div>

    <div class="card mt-3">
      <div class="card-body text-center">
        <span class="d-block fs-3 mb-3">จำนวนการรับริสแบนด์</span>
        <h2 class="d-block display-1">{{ timeline?.length }} / 10</h2>
      </div>
    </div>

    <div class="card mt-3" v-if="10 - timeline?.length">
      <div class="card-body text-center">
        <span class="d-block fs-3 mb-3">รับริสแบนด์จำนวน</span>
        <div class="qty d-flex justify-content-center gap-5 align-items-center">
          <span class="minus bg-dark" @click="removeInput">-</span>
          <span class="display-1"> {{ formInput?.length }} / {{ 10 - timeline?.length }} </span>
          <span class="plus bg-dark" @click="addInput">+</span>
        </div>
      </div>
    </div>


    <div class="mt-3" v-if="10 - timeline?.length">
      <form @submit.prevent="onSubmit" >
        <div v-for="(v, i) in formInput" :key="i">
          <div class="row g-3 align-items-center mb-3">
            <div class="col-4">
              <label for="inputPassword6" class="col-form-label text-white">ชื่อผู้รับริสแบนด์</label>
            </div>
            <div class="col">
              <input type="text" class="form-control" v-model="v.name" required>
            </div>
          </div>
          <div class="row g-3 align-items-center mb-3">
            <div class="col-4">
              <label for="inputPassword6" class="col-form-label text-white">เบอร์โทร</label>
            </div>
            <div class="col">
              <input type="text" class="form-control" v-model="v.phone" @keypress="NumberCheck" required>
            </div>
          </div>
        </div>

        <div class="d-grid gap-2 mt-3 ">
          <button class="btn btn-light fs-1" type="submit">ยืนยัน</button>
        </div>
      </Form>
    </div>

    <div class="mt-3" v-if="timeline?.length > 0">
      <div class="card">
        <div class="card-body" v-for="(v, i) in timeline" :key="i">
          <span class="fs-5 fw-bold">ผู้รับริสแบนด์คนที่ {{ i + 1 }}</span>
          <span class="mx-3 my-3">{{ `${v?.status}` }}</span><br>
          <span class="fs-5 fw-bold">เบอร์โทร</span>
          <span class="mx-3 my-3 ">{{ `${v?.status_detail}` }}</span>
          <hr v-if="i + 1 != timeline.length">
        </div>
      </div>
    </div>

  </div>
</template>

<script setup>
import { inject, onMounted, ref } from "vue"
import useBooking from "../service/api/booking"
import moment from "moment"
import useSweetalert from "@/service/sweetalert2"

const router = inject("router")
const store = inject("store")
const id = store.getters.getUrlCallBack.book

const userData = ref()
const table = ref()
const timeline = ref()

const { Sconfirm, SconfirmNc } = useSweetalert() // 
const { getOrderByID, sendBook} = useBooking()

onMounted(() => {
  if (!store.getters.getAccessToken.accesstoken) {
    router.push({ name: "Home" });
  }

  getOrderByID(id).then(({ data }) => {
    userData.value = data.data[0]
    table.value = data.table
    timeline.value = data.timeline
  })
})

const formInput = ref([{
  name: '',
  phone: ''
}])

const NumberCheck = (values) => (['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(values.key)) ? ((values.target.value.length < 10) ? true : values.preventDefault()) : values.preventDefault()

const addInput = () => (formInput.value.length < (10 - timeline.value.length) ? formInput.value.push({ name: null, phone: null}) : false)
const removeInput = () => (formInput.value.length > 1 ? formInput.value.shift() : false) 

const onSubmit = () => {
  let data = {
    id_line: store.getters.getAccessToken.accesstoken,
    arrays: formInput.value
  }


  Sconfirm("ยืนยันการ Check-in", "question").then(async (result) => {
    if (result.isConfirmed) {
      await sendBook(id, data).then(({ data }) => {
        if (data.status == "success") {
          SconfirmNc("Check in สำเร็จ", "success", "ตกลง").then(() => {
            getOrderByID(id).then(({ data }) => {
              userData.value = data.data[0]
              table.value = data.table
              timeline.value = data.timeline
            })

            formInput.value = [{name: '',phone: ''}]
          });
        } else {
          SconfirmNc("Check in ไม่สำเร็จ", "error", "ตกลง")
        }
      })
    }
  })
}

const dateThai = (date) => {
  var y = moment(date).format("yyy");
  var year = parseInt(y) + 543;
  var march = moment(date).locale("th");

  return march.format("D MMM" + year + ", LT");
}

</script>

<style scoped>
.bg-body {
  background: var(--color) !important;
}

.plus {
  cursor: pointer;
  display: inline-block;
  vertical-align: top;
  color: white;
  width: 30px;
  height: 30px;
  font: 30px/1 Arial, sans-serif;
  text-align: center;
  border-radius: 50%;
}

.minus {
  cursor: pointer;
  display: inline-block;
  vertical-align: top;
  color: white;
  width: 30px;
  height: 30px;
  font: 30px/1 Arial, sans-serif;
  text-align: center;
  border-radius: 50%;
  background-clip: padding-box;
}

.minus:hover {
  background-color: #717fe0 !important;
}

.plus:hover {
  background-color: #717fe0 !important;
}
</style>