export const SET_PROFIRELINE = "setProfireLine";
export const SET_PROFIRE = "setProfire";
export const SET_ACCESSTOKEN = "setAccessToken";
export const SET_URLCALLBACK = "setUrlCallback";

export default {
  state: {
    profire_line: [],
    profire: [],
    accesstoken: [],
    urlcallback: [],
  },
  getters: {
    getProfireLine(state) {
      return state.profire_line;
    },
    getProfire(state) {
      return state.profire;
    },
    getAccessToken(state) {
      return state.accesstoken;
    },
    getUrlCallBack(state) {
      return state.urlcallback;
    },
  },
  actions: {
    [SET_PROFIRELINE](state, payload) {
      state.commit(SET_PROFIRELINE, payload);
    },
    [SET_PROFIRE](state, payload) {
      state.commit(SET_PROFIRE, payload);
    },
    [SET_ACCESSTOKEN](state, payload) {
      state.commit(SET_ACCESSTOKEN, payload);
    },
    [SET_URLCALLBACK](state, payload) {
      state.commit(SET_URLCALLBACK, payload);
    },
  },
  mutations: {
    [SET_PROFIRELINE](state, payload) {
      state.profire_line = payload;
    },
    [SET_PROFIRE](state, payload) {
      state.profire = payload;
    },
    [SET_ACCESSTOKEN](state, payload) {
      state.accesstoken = payload;
    },
    [SET_URLCALLBACK](state, payload) {
      state.urlcallback = payload;
    },
  },
};
