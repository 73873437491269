import ApiService from "@/service/ApiService";

export default function useBooking() {
  const TableList = async () => {
    ApiService.setHeader();
    return await ApiService.query("tables");
  };
  const TableRegis = async () => {
    ApiService.setHeader()
    return await ApiService.query('tables_all')
  }

  const send_Table = async (value) => {
    return await ApiService.post('create_booking', value)
  }

  const getBooking = async (id) => {
    ApiService.setHeader();
    return await ApiService.query("getbooking_by_id/" + id);
  } 
  const getOrder = async () => {
    ApiService.setHeader();
    return await ApiService.query("getorder");
  } 

  const getOrderByID = async (id) => {
    ApiService.setHeader();
    return await ApiService.query("getbooking_by_booking_id/" + id);
  } 

  const sendPayment = async (id, value) => {
    return await ApiService.put("update_booking_payment/" + id, value)
  }

  const sendBook = async (id, value) => {
    return await ApiService.post("checkin/" + id, value)
  }

  return {
    TableList,
    TableRegis,
    send_Table,
    getBooking,
    getOrder,
    getOrderByID,
    sendPayment,
    sendBook
  };
}
