<template>
    <div class="bg-home vh100">

        <header class="container">
            <div class="row mx-auto">
                <img src="../assets/logo.png" class="img-fluid mx-auto" style="width: 65%;">
                <h1 class="text-center text-white">จองโต๊ะที่นั่ง</h1>
            </div>
        </header>



        <Form class="container" @submit="onSubmit">
            <div class="row g-3 align-items-center mb-3">
                <div class="col-3">
                    <label for="inputPassword6" class="col-form-label text-white">จองให้</label>
                </div>
                <div class="col">
                    <Field as="input" name="bookings_name" class="form-control"></Field>
                    <ErrorMessage class="text-danger" name="bookings_name" />
                </div>
            </div>
            
            <div class="row g-3 align-items-center mb-3">
                <div class="col-3">
                    <label for="inputPassword6" class="col-form-label text-white">โซน</label>
                </div>
                <div class="col">
                    <Field as="select" name="zone" class="form-control" v-model="select_zone" @change="selected">
                        <option value="" disabled selected>กรุณาเลือกโซน</option>
                        <option v-for="(v, i) in zone" :key="i" :value="v">{{ v }}</option>
                    </Field>
                    <ErrorMessage class="text-danger" name="zone" />
                </div>
            </div>
            <div class="row g-3 align-items-center mb-3">
                <div class="col-3">
                    <label for="inputPassword6" class="col-form-label text-white">จำนวนโต๊ะ</label>
                </div>
                <div class="col">
                    <Field as="select" name="booking_count" class="form-control" v-model="count">
                        <option value="" disabled selected>กรุณาเลือกจำนวนโต๊ะ</option>
                        <option v-for="(v, i) in 1"
                            :key="i" :value="v">{{ v }}</option>
                    </Field>
                    <ErrorMessage class="text-danger" name="booking_count" />
                </div>
            </div>

            <!-- <div class="row g-3 align-items-center mb-3" v-for="(v,i) in count" :key="v">
                <div class="col-3">
                    <label for="inputPassword6" class="col-form-label text-white">โต๊ะที่ {{ i }}</label>
                </div>
                <div class="col">
                    <Field as="select" class="form-control" name="table" v-model="booking_count[i]">
                        <option value="" disabled selected>กรุณาเลือกโต๊ะ</option>
                        <option v-for="(v, i) in selected_zone_list" :key="i" :value="v">{{ v.id }}</option>
                    </Field>
                    <ErrorMessage class="text-danger" name="table" />

                    <select v-model="booking_count[i]" class="form-control" @change="selected" :key="v">
                        <option value="" disabled selected>กรุณาเลือกโต๊ะ</option>
                        <option v-for="(v, i) in selected_zone_list" :key="i" :value="v.id">{{ v.id }}</option>
                    </select>
                </div>
            </div> -->

            <div class='d-inline row gap-2'>
                <div class="d-inline text-white"><span class="q free" /> ว่าง</div>
                <div class="d-inline text-white"><span class="q bg-danger" /> ติดจอง</div>
                <div class="d-inline text-white"><span class="q sele" /> โต๊ะที่เลือก</div>
            </div>

            <!-- <div v-if="select_zone && count" class="row mx-auto">
                <div class="col-3 p-0 m-0" v-for="v in selected_zone_list" :key="v">
                    <div v-if="regis_tables.includes(v.id)" class="d-flex align-items-center">
                        <label class="box bg-danger">
                            {{ v.id }}
                        </label>
                    </div>
                    <div v-else class="d-flex align-items-center">
                        <label class="form-check-label box" :for="v.id"
                            :class="{ 'checked': booking_count.includes(v.id) ? true : false }">
                            {{ v.id }}
                        </label>

                        <Field class="form-check-input d-none" type="checkbox" name="booking_table" :id="v.id" :value="v.id"
                            v-model="booking_count"
                            :disabled="(booking_count.length > (count - 1) ? (booking_count.includes(v.id) ? false : true) : false)" />

                    </div>
                </div>
            </div> -->

            <table class="table table-borderless" v-if="select_zone && count">
                <tr v-for="(ls) in loops" :key="ls" class="booking">
                    <td v-for="(p) in 5" :key="p">
                        <div v-if="none.includes(`${select_zone}${ls}-${Number((double.includes(select_zone) ? Number(p) +5 : p))}`)">
                            <label class=""></label>
                        </div>
                        <div v-else-if="!(selected_zone_list.map(m => m.id).includes(`${select_zone}${ls}-${Number((double.includes(select_zone) ? Number(p) +5 : p))}`))">
                            <label class="box bg-danger text-white">
                                {{ `${select_zone}${ls}-${Number((double.includes(select_zone) ? Number(p) +5 : p))}` }}
                            </label>
                        </div>
                        <div v-else-if="regis_tables.includes(`${select_zone}${ls}-${Number((double.includes(select_zone) ? Number(p) +5 : p))}`)">
                            <label class="box bg-danger">
                                {{ `${select_zone}${ls}-${Number((double.includes(select_zone) ? Number(p) +5 : p))}` }}
                            </label>
                        </div>
                        <div v-else>
                            <label class="form-check-label box" :for="`${select_zone}${ls}-${Number((double.includes(select_zone) ? Number(p) +5 : p))}`"
                                :class="{ 'checked': booking_count.includes(`${select_zone}${ls}-${Number((double.includes(select_zone) ? Number(p) +5 : p))}`) ? true : false }">
                                {{ `${select_zone}${ls}-${Number((double.includes(select_zone) ? Number(p) +5 : p))}` }}
                            </label>

                            <Field class="form-check-input d-none" type="checkbox" name="booking_table" :id="`${select_zone}${ls}-${Number((double.includes(select_zone) ? Number(p)+5 : p))}`" :value="`${select_zone}${ls}-${Number((double.includes(select_zone) ? Number(p)+5 : p))}`"
                            v-model="booking_count"
                            :disabled="(booking_count.length > (count - 1) ? (booking_count.includes(`${select_zone}${ls}-${Number((double.includes(select_zone) ? Number(p)+5 : p))}`) ? false : true) : false)" />
                        </div>

                    </td>
                </tr>
                <tr class="booking">
                    <td colspan="12" class="gray">เวทีกิจกรรมกลาง</td>
                </tr>
            </table>



            <!-- <div class="d-grid gap-2 mt-3 mx-5" v-if="select_zone && count">
                <button type="submit" class="btn btn-light rounded-pill">ยืนยันการจอง</button>
            </div> -->
            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-body">
                            <span class="fs-3 fw-bold text-center d-block">ใบยืนยันการจอง</span>
                            <span class="fs-4 fw-bold mt-3 d-block">Order #{{ order?.order_id }}</span>
                            <hr class="border border-darks my-2">
                            <table class="table table-borderless">
                                <thead>
                                    <tr class="align-items-center align-middle text-center border-bottom border-darks">
                                        <td>รายการ</td>
                                        <td>จำนวน</td>
                                        <td>ราคาต่อหน่วย</td>
                                        <td>ราคารวม</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <span v-for="bc in booking_count" :key="bc">โต๊ะหมายเลข {{ bc }}<br></span>
                                        </td>
                                        <td class="text-center">{{ booking_count.length }}</td>
                                        <td class="text-center">3,500</td>
                                        <td class="text-end">{{ (booking_count.length * 3500).toLocaleString() }}</td>
                                    </tr>
                                    <tr class="border-top border-bottom border-darks">
                                        <td class="fw-bold">ราคารวม</td>
                                        <td></td>
                                        <td></td>
                                        <td class="fw-bold text-end">{{ (booking_count.length * 3500).toLocaleString() }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="mt-4">
                                <button type="submit" class="btn btn-success" data-bs-dismiss="modal">ยืนยันการจอง</button>
                                <button type="button" class="btn btn-secondary ms-2" data-bs-dismiss="modal">ปิด</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-grid gap-2">
                <button type="button" class="btn btn-light mt-2 float-end" data-bs-toggle="modal"
                    data-bs-target="#exampleModal" @click="getOrders">
                    ตรวจสอบข้อมูล
                </button>
            </div>
        </Form>


    </div>
</template>

<script setup>
import { inject, onMounted, ref } from "vue";
import useSweetalert from "@/service/sweetalert2";
import useBooking from "../service/api/booking";
import { ErrorMessage, Field, Form } from "vee-validate"
// import Loading from "vue-loading-overlay";

const { Sconfirm, SconfirmNc } = useSweetalert();
const store = inject("store")
const router = inject("router")
const { TableList, TableRegis, send_Table, getOrder } = useBooking()

const double = ['B','D', 'F', 'H']
const loops = ['E', 'D', 'C', 'B', 'A']
const none = ['AA-5', 'BA-6', 'CA-5', 'CB-5', 'CC-5', 'CD-5', 'DA-6', 'DB-6', 'DC-6', 'DD-6']
// let isLoading = ref(false);

const tables = ref(null)
const zone = ref(null)
const regis_tables = ref(null)
const booking_count = ref([])
const selected_zone_list = ref(null)
const select_zone = ref(null)
const count = ref(null)
const order = ref(null)

onMounted(() => {
    if (!store.getters.getAccessToken.accesstoken) {
        router.push({ name: "Home" });
    }
    TableList().then(({ data }) => {
        tables.value = data.map(s => ({ id: s.table_code }))
        zone.value = removeDup(tables.value.map(s => s.id[0])).sort()
    })
    TableRegis().then(({ data }) => {
        regis_tables.value = data.map(s => (s.table_code))
    })
})

const selected = () => {
    selected_zone_list.value = tables.value.filter(fil => fil.id[0] == select_zone.value).filter(fil => booking_count.value?.indexOf(fil.id))
    booking_count.value = []
    count.value = null
}
const removeDup = (data) => data.filter((v, i) => data.indexOf(v) === i)

const getOrders = () => {
    getOrder().then(({ data }) => {
        order.value = data
    })
}

const onSubmit = (values) => {
    if (booking_count.value.length != count.value) return SconfirmNc("กรุณาเลือกโต๊ะให้ครบ", "error", "ตกลง")

    values.id = store.getters.getAccessToken.accesstoken
    Sconfirm("ยืนยันการจองโต๊ะ", "question").then(async (result) => {
        if (result.isConfirmed) {
            await send_Table(values).then(({ data }) => {
                if (data.status == "Success") {
                    SconfirmNc("จองโต๊ะสำเร็จ", "success", "ตกลง").then(() => {
                        router.push({ path: "/book/" + data.booking_id });
                    });
                } else {
                    SconfirmNc("จองโต๊ะไม่สำเร็จ", "error", "ตกลง")

                    TableList().then(({ data }) => {
                        tables.value = data.map(s => ({ id: s.table_code }))
                        zone.value = removeDup(tables.value.map(s => s.id[0]))
                    })
                    TableRegis().then(({ data }) => {
                        regis_tables.value = data.map(s => (s.table_code))
                    })
                }
            })
        }
    })
}

</script>


<style scoped>
.gray     { height: 40px !important; background: #9d9d9d }
tr.booking td {
    color: white;
    text-align: center;
    font-weight: bold;
}

hr {
    opacity: .7 !important;
}

.border-darks {
    border-color: black !important;
}

.checked {
    background-color: goldenrod !important;
    color: white;
}

.box {
    background-color: lightgreen;
    color: dimgray;
    height: 80px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.bg-danger {
    color: white ;
}

.q {
    display: inline-block;
    width: 10px !important;
    height: 10px !important;
}

.free {
    background-color: lightgreen;
}

.sele {
    background-color: goldenrod;
    color: white !important; 
}
</style>