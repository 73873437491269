<template>
    <div class="bg-home vh100">
        <header class="container">
            <div class="row mx-auto">
                <img src="../assets/logo.png" class="img-fluid mx-auto" style="width: 65%;">
                <h1 class="text-center text-white">ประวัติการจอง</h1>
            </div>
        </header>


        <div class="container">
            <div class="row">
                <div class="col rounded-5 bg-white mx-2 p-3 mb-3" v-for="v in book" :key="v">
                    <div class="row mb-2">
                        <div class="col">
                            <span class="fw-bold d-block">หมายเลขการจอง</span>
                            <span class="fw-bold fs-4 text-gray d-block">{{ v.booking_number }}</span>
                        </div>
                        <div class="col d-flex justify-content-end align-items-center" :class="{'text-success': (v.status == 'ชำระเงินสำเร็จ'), 'text-danger': (v.status == 'รอการชำระเงิน'), 'text-warning': (v.status == 'รอยืนยันการชำระเงิน')}">
                            <span class="float-end d-inline">{{ v.status }}</span>
                        </div>
                    </div>
                    <table class="table table-borderless">
                        <thead>
                            <tr class="align-items-center align-middle text-center border-top border-bottom border-darks">
                                <td>รายการ</td>
                                <td>จำนวน</td>
                                <td>ราคารวม</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <span>โต๊ะหมายเลข {{ table.table_code }}<br></span>
                                </td>
                                <td class="text-center">{{ v.booking_count }}</td>
                                <td class="text-end">{{ (v.booking_count * 3500).toLocaleString() }}</td>
                            </tr>
                            <tr class="border-top border-bottom border-darks">
                                <td class="fw-bold">ราคารวม</td>
                                <td></td>
                                <td class="fw-bold text-end">{{ (v.booking_count * 3500).toLocaleString() }}</td>
                            </tr>
                        </tbody>
                    </table>

                    <span v-if="v.status != 'รอยืนยันการชำระเงิน' && v.status != 'ชำระเงินสำเร็จ'">
                        <span class="fw-bold">เงื่อนไขการชำระเงิน</span>
                        <div class="row gy-2">
                            <label class="col-3">โอนเข้าบัญชี</label>
                            <label class="col-9 fw-bold">ธนาคารไทยพาณิชย์ สาขาเซ็นทรัลวงศ์สว่าง</label>
                            <label class="col-3">ประเภทบัญชี</label>
                            <label class="col-9 fw-bold">ออมทรัพย์</label>
                            <label class="col-3">ชื่อบัญชี</label>
                            <label class="col-9 fw-bold">นางสาววิภาวัลย์ วรวรรณปรีชาและนางอ้อมคอน  เจียวปลิ้มจิตร</label>
                            <label class="col-3 d-flex align-items-center">เลขที่บัญชี</label>
                            <label class="col-9 fw-bold fs-3">271-255243-4</label>
                        </div>
                        <hr>
                    </span> 

                    <span v-if="v.status == 'ชำระเงินสำเร็จ'" class="d-block text-center">
                        <span class="d-block mb-3 fs-5">Qr Code สำหรับ Check IN</span>
                        <qrcode-vue :value="`line://app/${liff}?book=${id}`" :size="250" level="H"  />
                    </span>

                    
                    <Form @submit="PayBook" v-if="v.status != 'รอยืนยันการชำระเงิน' && v.status != 'ชำระเงินสำเร็จ'">
                        <span class="d-block mb-3">ส่งหลักฐานการชำระเงิน</span>

                        <img v-if="imageUrl" :src="imageUrl" class="img-fluid mb-3">

                        <input type="file" id="getFile" multiple="multiple" accept="image/jpg, image/jpeg ,image/png" class="d-none" @input="pickFile" name="values"/>
                        <!-- <button type="button" class="form-control btn btn-outline-secondary" @click="selectImage">แนบรูป</button> -->
                        <label for="getFile" class="form-control btn btn-outline-secondary">แนบรูป</label>
                        <Field type="input" class="d-none" name="booking_number" :value="v.booking_number"/>
                        <Field type="input" class="d-none" name="pay_amount" :value="(v.booking_count * 3500)"/>

                        <button class="btn btn-success float-end mt-3">ยืนยันการชำระเงิน</button>
                    </form>
                    

                </div>
            </div>
        </div>
        <div class="d-grid gap-2 mx-5">
            <button @click="back" class="btn btn-light">กลับหน้าหลัก</button>
        </div>
        


    </div>
</template>

<script setup>
import { inject, onMounted, ref } from "vue";
import { useRoute } from "vue-router";

import QrcodeVue from 'qrcode.vue'

import useSweetalert from "@/service/sweetalert2";

import useBooking from "../service/api/booking";

import { Field, Form } from "vee-validate"
// import Loading from "vue-loading-overlay";

const liff = process.env.VUE_APP_LIFF
const { Sconfirm, SconfirmNc } = useSweetalert();
const store = inject("store")
const route = useRoute()
const router = inject("router")
const id = route.params.id
const { getOrderByID, sendPayment } = useBooking()

const PayBook = async (values) => {
    values.imageUrl = imageUrl.value
    values.id = store.getters.getAccessToken.accesstoken
    let now = new Date()
    let hour = now.getHours()
    let minute = now.getMinutes()

    values.pay_date = now
    values.pay_time = hour + ":" + minute


    Sconfirm("ยืนยันการชำระเงิน", "question").then(async (result) => {
        if (result.isConfirmed) {
            await sendPayment(route.params.id, values).then(() => {
                SconfirmNc("ชำระเงินสำเร็จ", "success", "ตกลง").then(() => {
                    router.push({ path: "/book" });
                });
            })
        }
    })
}

const table = ref(null)
const book = ref(null)
const imageUrl = ref(null)

const back = () => router.push({ name: "Book" })
// const selectImage = () => document.getElementById("getFile").click()

const pickFile = async (e) => {
    const files = e.target.files
    imageUrl.value = await reduce_image_file_size(await image_to_base64(files[0]))
}

async function reduce_image_file_size(
      base64Str,
      MAX_WIDTH = 1200,
      MAX_HEIGHT = 1200
    ) {
      let resized_base64 = await new Promise((resolve) => {
        let img = new Image();

        img.src = base64Str;
        img.onload = () => {
          let canvas = document.createElement("canvas");
          let width = img.width;
          let height = img.height;

          if (width > height) {
            if (width > MAX_WIDTH) {
              height *= MAX_WIDTH / width;
              width = MAX_WIDTH;
            }
          } else {
            if (height > MAX_HEIGHT) {
              width *= MAX_HEIGHT / height;
              height = MAX_HEIGHT;
            }
          }
          canvas.width = width;
          canvas.height = height;
          let ctx = canvas.getContext("2d");

          ctx.drawImage(img, 0, 0, width, height);

          resolve(canvas.toDataURL("image/jpeg")); // this will return base64 image results after resize
        };
      });
      return resized_base64;
    }

    async function image_to_base64(file) {
      let result_base64 = await new Promise((resolve) => {
        let fileReader = new FileReader();

        fileReader.onload = () => resolve(fileReader.result);
        fileReader.onerror = (error) => {
          console.log(error);
          alert("An Error occurred please try again, File might be corrupt");
        };
        fileReader.readAsDataURL(file);
      });

      return result_base64;
    }

onMounted(() => {
    if (!store.getters.getAccessToken.accesstoken) {
        router.push({ name: "Home" });
    }
    
    getOrderByID(route.params.id).then(({ data }) => {
        book.value = data.data
        table.value = data.table
    })
})

</script>

<style scoped>
.rounded-5 {
    border-radius: 1rem;
}
</style>