<template>
  <div class="bg-home vh100">
    <header class="container">
      <div class="row mx-auto">
        <img src="../assets/logo.png" class="img-fluid mx-auto" style="width: 65%;">
        <h1 class="text-center text-white">วิธีการและเงื่อนไขการจอง</h1>
      </div>
    </header>

    <div class="container">
      <div class="row">
        <div class="col mx-auto">
          <span class="text-white">
          เงื่อนไขการจอง<br>
          1. สามารถจองโต๊ะที่ต้องการ ได้สูงสุด 10 โต๊ะ ต่อการจอง 1 ครั้ง<br>
          2. ราคาจำหน่ายโต๊ะ 3,500 บาท ต่อ 1 โต๊ะ จำนวน 10 ที่นั่ง<br><br>
          *** หมายเหตุ<br>
          1. เมื่อลูกค้าทำรายการสั่งซื้อแล้ว ทางผู้จัดงานขอสงวนสิทธิ์ไม่สามารถขอเปลี่ยนหรือคืนโต๊ะที่จองได้ทุกกรณี<br>
          2. ติดต่อสอบถามข้อมูลเพิ่มเดิมได้ที่<br>
          โทร : 02 - 966-5700 , 089 - 4953452 ( รองจันทร์เพ็ญ ) ในวันและเวลาราชการ<br>
          โทร : 080 -430-7070 (ฝ่ายการเงิน ) ในวันและเวลาราชการ<br>
          โทร : 096 -717-9582 (แอดมิน)<br>
          และ Line @937wfaro<br>
          <br><br>
          วิธีการจอง<br>
          1. เมื่อท่านเข้าสู่ระบบ > ตรวจสอบเงื่อนไขการจอง > กคเลือก จองโต๊ะที่นั่ง<br>
          2. กดเลือก จองโต๊ะที่นั่ง > เลือกโซน > กดจำนวน โต๊ะที่ต้องการจอง ><br>
          กดเลือก ตรวจสอบข้อมูล > เลือก โต๊ะที่ต้องการจอง > กดเลือก ตรวจสอบข้อมูล<br>
          3. ตรวจสอบรายละเอียดการจองของท่าน เลขโต๊ะ จำนวนโต๊ะ และยอดเงิน > กดยืนยันการจอง > ชำระเงิน > <br>
          แนบรูป ( สลิป การโอน ) > กด ยืนยันการชำระเงิน<br>
          4. เมื่อท่านแนบรูป สลิป การโอนเรียบร้อย จะขึ้นสถานะ รอยืนยันการชำระเงิน<br>
          5. ท่านกดเลือก กลับหน้าหลัก เพื่อตรวจสอบประวัติการจองของท่าน<br>
          6. ระบบจะ อัพเดท การจองและการชำระเงินของท่าน เพื่อจัดส่ง QR CODE โต๊ะของท่าน<br>
          ภายใน 24 ชั่วโมง ผ่านช่องทาง ที่ท่านเลือกไว้ e-mail , line อื่นๆ<br>
        </span>

          <div class="d-grid gap-2 mt-3">
            <button type="button" class="btn btn-light rounded-pill" @click="go_layout">จองโต๊ะที่นั่ง</button>
            <button type="button" class="btn btn-light rounded-pill" @click="go_book">ประวัติการจอง</button>
          </div>
          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, inject } from "vue";
export default defineComponent({
  name: "Dashboard",
  setup() {
    const VUE_APP_NAME_LINE = process.env.VUE_APP_NAME_LINE
    const router = inject("router");
    const store = inject("store");
    onMounted(() => {
      if (!store.getters.getAccessToken.accesstoken) {
        router.push({ name: "Home" });
      }
    });

    const go_layout = () => {
      router.push({ name: "Layout" });
    };
    const go_book = () => router.push({ name: "Book"})


    return {
      go_book,
      go_layout,
      VUE_APP_NAME_LINE
    };
  },
});

</script>


<style scoped>
.art-work {
  justify-content: center;
  align-items: center;
  display: flex;
  height: 500px;
  background: var(--color);
  color: white;
}

.submit-button {
  color: #4E6629 !important;
  position: relative;
  box-sizing: border-box;
  text-align: center;
  width: 150px;
  border-radius: 25px;
  border: none;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  padding: 8px 10px 8px 10px;
}

.submit-button:hover,
.submit-button:focus {
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
  color: rgba(0, 0, 0, 0.65);
}

.submit-button:hover {
  transform: translateY(-1px);
}

.submit-button:active {
  background-color: #f0f0f1;
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
  color: rgba(0, 0, 0, 0.65);
  transform: translateY(0);
}

.bg-home {
  background-color: var(--color);
  overflow-y: scroll;
  padding-bottom: 20px;
}
</style>
