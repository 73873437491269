export const SET_BOOKING = "setBooking";

export default {
  state: {
    booking: [],
  },
  getters: {
    getBooking(state) {
      return state.booking;
    },
  },
  actions: {
    [SET_BOOKING](state, payload) {
      state.commit(SET_BOOKING, payload);
    },
  },
  mutations: {
    [SET_BOOKING](state, payload) {
      state.booking = {
        ...state.booking,
        ...payload,
      };
    },
  },
};
