<template>
    <div class="bg-home vh100">
        <header class="container mb-3">
            <div class="row mx-auto">
                <img src="../assets/logo.png" class="img-fluid mx-auto" style="width: 65%;">
                <h1 class="text-center text-white">ประวัติการจอง</h1>
            </div>
        </header>


        <div class="container" v-if="book?.length > 0">
            <div class="row">
                <div class="col rounded-5 bg-white mx-5 p-2 shadow p-3 mb-3 bg-body-tertiary rounded" v-for="v in book"
                    :key="v" @click="go_to(v.id)">
                    <div class="row">
                        <div class="col">
                            <span class="fw-bold d-block">หมายเลขการจอง</span>
                            <span class="fw-bold fs-4 text-gray d-block">{{ v.booking_number }}</span>
                        </div>
                        <div class="col d-flex justify-content-end align-items-center me-3 fw-bold">
                            <span class="me-3"
                                :class="{ 'text-success': (v.status == 'ชำระเงินสำเร็จ'), 'text-danger': (v.status == 'รอการชำระเงิน'), 'text-warning': (v.status == 'รอยืนยันการชำระเงิน') }">{{
                                    v.status }}</span>
                            <img src="https://cdn-icons-png.flaticon.com/512/271/271228.png" width="20">
                        </div>
                    </div>
                    <!-- <hr>
                    <span class="fw-bold text-start d-block fs-">
                        สถานะ<span class="fw- float-end d-inline">{{ v.status }}</span>
                    </span> -->

                </div>
            </div>
            <div class="d-grid gap-2 mx-5 mt-3">
                <button @click="back" class="btn btn-light">กลับหน้าหลัก</button>
            </div>
        </div>
        <div v-else>
            <span class="text-center my-4 d-block fs-3 text-white">ไม่พบการจอง</span>
            <div class="d-grid gap-2 mx-5 mt-3">
                <button @click="books" class="btn btn-light">จองโต๊ะ</button>
            </div>
        </div>





    </div>
</template>

<script setup>
import { inject, onMounted, ref } from "vue";

// import useSweetalert from "@/service/sweetalert2";

import useBooking from "../service/api/booking";

// import { ErrorMessage, Field, Form } from "vee-validate"
// import Loading from "vue-loading-overlay";

// const { Sconfirm, SconfirmNc } = useSweetalert();
const store = inject("store")
const router = inject("router")

const { getBooking } = useBooking()

const book = ref(null)
const go_to = (id) => router.push({ path: "/book/" + id })
const books = () => router.push({ name: "Booking" })
const back = () => router.push({ name: "Home" })
onMounted(() => {
    if (!store.getters.getAccessToken.accesstoken) {
        router.push({ name: "Home" });
    }

    getBooking(store.getters.getAccessToken.accesstoken).then(({ data }) => {
        book.value = data.data
    })
})

</script>

<style scoped>
.rounded-5 {
    border-radius: .8rem;
}
</style>