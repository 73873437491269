<template>
<table class="table table-borderless">
    <tr>
        <td colspan="12" class="gray">เวทีกิจกรรมกลาง</td>
    </tr>
    <tr>
        <td colspan="12">&nbsp;</td>
    </tr>
    <tr>
        <td class="blue">BA-10</td>
        <td class="blue">BA-9</td>
        <td class="blue">BA-8</td>
        <td class="blue">BA-7</td>
        <td colspan="4">&nbsp;</td>
        <td class="yellow">AA-4</td>
        <td class="yellow">AA-3</td>
        <td class="yellow">AA-2</td>
        <td class="yellow">AA-1</td>
    </tr>
    <tr>
        <td class="blue">BB-10</td>
        <td class="blue">BB-9</td>
        <td class="blue">BB-8</td>
        <td class="blue">BB-7</td>
        <td>&nbsp;</td>
        <td class="blue">BB-6</td>
        <td class="yellow">AB-5</td>
        <td>&nbsp;</td>
        <td class="yellow">AB-4</td>
        <td class="yellow">AB-3</td>
        <td class="yellow">AB-2</td>
        <td class="yellow">AB-1</td>
    </tr>
    <tr>
        <td class="blue">BC-10</td>
        <td class="blue">BC-9</td>
        <td class="blue">BC-8</td>
        <td class="blue">BC-7</td>
        <td>&nbsp;</td>
        <td class="blue">BC-6</td>
        <td class="yellow">AC-5</td>
        <td>&nbsp;</td>
        <td class="yellow">AC-4</td>
        <td class="yellow">AC-3</td>
        <td class="yellow">AC-2</td>
        <td class="yellow">AC-1</td>
    </tr>
    <tr>
        <td class="blue">BD-10</td>
        <td class="blue">BD-9</td>
        <td class="blue">BD-8</td>
        <td class="blue">BD-7</td>
        <td>&nbsp;</td>
        <td class="blue">BD-6</td>
        <td class="yellow">AD-5</td>
        <td>&nbsp;</td>
        <td class="yellow">AD-4</td>
        <td class="yellow">AD-3</td>
        <td class="yellow">AD-2</td>
        <td class="yellow">AD-1</td>
    </tr>
    <tr>
        <td class="blue">BE-10</td>
        <td class="blue">BE-9</td>
        <td class="blue">BE-8</td>
        <td class="blue">BE-7</td>
        <td>&nbsp;</td>
        <td class="blue">BE-6</td>
        <td class="yellow">AE-5</td>
        <td>&nbsp;</td>
        <td class="yellow">AE-4</td>
        <td class="yellow">AE-3</td>
        <td class="yellow">AE-2</td>
        <td class="yellow">AE-1</td>
    </tr>
    <tr><td colspan="12">&nbsp;</td></tr>
    <tr>
        <td class="orange">DA-10</td>
        <td class="orange">DA-9</td>
        <td class="orange">DA-8</td>
        <td class="orange">DA-7</td>
        <td rowspan="4">&nbsp;</td>
        <td rowspan="4" class="bg-white text-black">DANCE </td>
        <td rowspan="4" class="bg-white text-black">FLOOR</td>
        <td rowspan="4">&nbsp;</td>
        <td class="green">CA-4</td>
        <td class="green">CA-3</td>
        <td class="green">CA-2</td>
        <td class="green">CA-1</td>
    </tr>
    <tr>
        <td class="orange">DB-10</td>
        <td class="orange">DB-9</td>
        <td class="orange">DB-8</td>
        <td class="orange">DB-7</td>
        <td class="green">CB-4</td>
        <td class="green">CB-3</td>
        <td class="green">CB-2</td>
        <td class="green">CB-1</td>
    </tr>
    <tr>
        <td class="orange">DC-10</td>
        <td class="orange">DC-9</td>
        <td class="orange">DC-8</td>
        <td class="orange">DC-7</td>
        <td class="green">CC-4</td>
        <td class="green">CC-3</td>
        <td class="green">CC-2</td>
        <td class="green">CC-1</td>
    </tr>
    <tr>
        <td class="orange">DD-10</td>
        <td class="orange">DD-9</td>
        <td class="orange">DD-8</td>
        <td class="orange">DD-7</td>
        <td class="green">CD-4</td>
        <td class="green">CD-3</td>
        <td class="green">CD-2</td>
        <td class="green">CD-1</td>
    </tr>
    <tr>
        <td class="orange">DE-10</td>
        <td class="orange">DE-9</td>
        <td class="orange">DE-8</td>
        <td class="orange">DE-7</td>
        <td>&nbsp;</td>
        <td class="orange">DE-6</td>
        <td class="green">CE-5</td>
        <td>&nbsp;</td>
        <td class="green">CE-4</td>
        <td class="green">CE-3</td>
        <td class="green">CE-2</td>
        <td class="green">CE-1</td>
    </tr>
    <tr><td colspan="12">&nbsp;</td></tr>
    <tr>
        <td class="pink">FA-10</td>
        <td class="pink">FA-9</td>
        <td class="pink">FA-8</td>
        <td class="pink">FA-7</td>
        <td>&nbsp;</td>
        <td class="pink">FA-6</td>
        <td class="sky">EA-5</td>
        <td>&nbsp;</td>
        <td class="sky">EA-4</td>
        <td class="sky">EA-3</td>
        <td class="sky">EA-2</td>
        <td class="sky">EA-1</td>
    </tr>
    <tr>
        <td class="pink">FB-10</td>
        <td class="pink">FB-9</td>
        <td class="pink">FB-8</td>
        <td class="pink">FB-7</td>
        <td>&nbsp;</td>
        <td class="pink">FB-6</td>
        <td class="sky">EB-5</td>
        <td>&nbsp;</td>
        <td class="sky">EB-4</td>
        <td class="sky">EB-3</td>
        <td class="sky">EB-2</td>
        <td class="sky">EB-1</td>
    </tr>
    <tr>
        <td class="pink">FC-10</td>
        <td class="pink">FC-9</td>
        <td class="pink">FC-8</td>
        <td class="pink">FC-7</td>
        <td>&nbsp;</td>
        <td class="pink">FC-6</td>
        <td class="sky">EC-5</td>
        <td>&nbsp;</td>
        <td class="sky">EC-4</td>
        <td class="sky">EC-3</td>
        <td class="sky">EC-2</td>
        <td class="sky">EC-1</td>
    </tr>
    <tr>
        <td class="pink">FD-10</td>
        <td class="pink">FD-9</td>
        <td class="pink">FD-8</td>
        <td class="pink">FD-7</td>
        <td>&nbsp;</td>
        <td class="pink">FD-6</td>
        <td class="sky">ED-5</td>
        <td>&nbsp;</td>
        <td class="sky">ED-4</td>
        <td class="sky">ED-3</td>
        <td class="sky">ED-2</td>
        <td class="sky">ED-1</td>
    </tr>
    <tr>
        <td class="pink">FE-10</td>
        <td class="pink">FE-9</td>
        <td class="pink">FE-8</td>
        <td class="pink">FE-7</td>
        <td>&nbsp;</td>
        <td class="pink">FE-6</td>
        <td class="sky">EE-5</td>
        <td>&nbsp;</td>
        <td class="sky">EE-4</td>
        <td class="sky">EE-3</td>
        <td class="sky">EE-2</td>
        <td class="sky">EE-1</td>
    </tr>

    <tr><td colspan="12">&nbsp;</td></tr>
    
    <tr>
        <td class="gold">HA-10</td>
        <td class="gold">HA-9</td>
        <td class="gold">HA-8</td>
        <td class="gold">HA-7</td>
        <td>&nbsp;</td>
        <td class="gold">HA-6</td>
        <td class="red">GA-5</td>
        <td>&nbsp;</td>
        <td class="red">GA-4</td>
        <td class="red">GA-3</td>
        <td class="red">GA-2</td>
        <td class="red">GA-1</td>
    </tr>
    <tr>
        <td class="gold">HB-10</td>
        <td class="gold">HB-9</td>
        <td class="gold">HB-8</td>
        <td class="gold">HB-7</td>
        <td>&nbsp;</td>
        <td class="gold">HB-6</td>
        <td class="red">GB-5</td>
        <td>&nbsp;</td>
        <td class="red">GB-4</td>
        <td class="red">GB-3</td>
        <td class="red">GB-2</td>
        <td class="red">GB-1</td>
    </tr>
</table>
</template>

<style scoped>
tr td { 
  color: white;
  text-align: center;
  font-weight: bold;
}
.gray     { height: 40px !important; background: #9d9d9d }
.yellow   { height: 40px !important; background: #fbff00; color: black }
.blue     { height: 40px !important; background: #0d6efd; color: black}
.green    { height: 40px !important; background: #107e19; color: white}
.orange   { height: 40px !important; background: #ffbf00; color: black}
.sky      { height: 40px !important; background: #50d9ff; color: black}
.pink     { height: 40px !important; background: #ff00d4; color: black}
.red      { height: 40px !important; background: red; color: black}
.gold     { height: 40px !important; background: goldenrod; color: black}
</style>